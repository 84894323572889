import {defineStore} from "pinia";
import {useLocalStorage} from "@vueuse/core";

export const useAppStore = defineStore({
    id: 'app',
    state: () => ({
        listView: useLocalStorage('listView', 'table'),
        changeView: useLocalStorage('changeView', 'form'),
        miscView: useLocalStorage('miscView', 'grid'),
        orderView: useLocalStorage('orderView', 'table'),
        productListView: useLocalStorage('productListView', 'table'),
        productChangeView: useLocalStorage('productChangeView', 'dashboard'),
        variationListView: useLocalStorage('variationListView', 'table'),
        variationChangeView: useLocalStorage('variationChangeView', 'dashboard'),
        unitListView: useLocalStorage('unitListView', 'table'),
        unitChangeView: useLocalStorage('unitChangeView', 'dashboard'),
        operationListView: useLocalStorage('operationListView', 'grid'),
        operationChangeView: useLocalStorage('operationChangeView', null),

        loadSideBar: false,
        orderTypes: [
            {id: "local_pickup", name: "Tienda"},
            {id: "flat_rate", name: "Peninsula"},
            {id: "shipping_baleares", name: "Baleares"},
            {id: "shipping_test", name: "Test"},
            {id: "shipping_pickup_wow", name: "WOW"},
            {id: "free_shipping", name: "Peninsula Gratis"},
            {id: "from_baleares_return_shop_shipping", name: "Baleares / Tienda"},
            {id: "from_peninsula_return_shop_shipping", name: "Peninsula / Tienda"},
            {id: "from_shop_return_baleares_shipping", name: "Tienda / Baleares"},
            {id: "from_shop_return_peninsula_shipping", name: "Tienda / Peninsula"},
        ],
        deliveryTypes: [
            {id: "delivery", name: "Entrega a domicilio"},
            {id: "pickup", name: "Recogida en tienda"},
            {id: "wow", name: "Recogida en WOW"},
            {id: "delivery_pickup", name: "Envío y recogida"},
            {id: "pickup_delivery", name: "Recogida y Envío"},
        ],
        bookingStatuses: [
            {id: "pending", name: "Pendiente"},
            {id: "blocking", name: "Bloqueo"},
            {id: "shipping", name: "Envío"},
            {id: "renting", name: "Alquiler"},
            {id: "closed", name: "Cerrado"},
            {id: "open", name: "Abierto"},
        ],
        saleStatuses: [
            {id: "pending", name: "Pendiente"},
            {id: "closed", name: "Cerrado"},
            {id: "open", name: "Abierto"},
        ],
        toasts: [],
        wcUrl: "https://bor2024pre:OcultA24+@pre.borow.es",
    }),
    actions: {
        triggerSidebarLoad() {
            this.loadSideBar = true;
        },
        resetSidebarLoad() {
            this.loadSideBar = false;
        },
        addToast(title, message, color = 'secondary') {
            const today = new Date()
            this.toasts.push({
                id: Math.random(),
                date: today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds(),
                title: title,
                message: message,
                color: color
            });
        },


    }

});
